
<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Payment Transaction Events</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Payment Transaction Events</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="PaymentTransactionEvent">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'PaymentTransactionEventList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-6 required">
                  <label class="control-label">Event Type</label>
                  <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('event_type')}" v-validate="'required'" name="event_type" v-model="value.event_type" id="event_type">
                    <option value="Sync-Status">Sync-Status</option>
                    <option value="Notify">Notify</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('event_type')">
                    {{ errors.first("event_type") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Event State</label>
                  <select class="form-control" style="width: 100%;" v-bind:class="{'has-error' : errors.has('event_state')}" v-validate="'required'" name="event_state" v-model="value.event_state" id="event_state">
                    <option value="Created">Created</option>
                    <option value="Processing">Processing</option>
                    <option value="Done">Done</option>
                  </select>
                  <div class="help text-danger" v-show="errors.has('event_state')">
                    {{ errors.first("event_state") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Event Data</label>
                  <textarea id="event_data" name="event_data" v-bind:class="{'has-error' : errors.has('event_data')}" v-validate="'required'" v-model="value.event_data" class="form-control event_data" placeholder="Event Data"></textarea>
                  <div class="help text-danger" v-show="errors.has('event_data')">
                    {{ errors.first("event_data") }}
                  </div>
                </div>
                <div class="form-group col-6">
                  <label class="control-label">Remarks</label>
                  <textarea id="remarks" name="remarks" v-bind:class="{'has-error' : errors.has('remarks')}" v-model="value.remarks" class="form-control remarks" placeholder="Remarks"></textarea>
                  <div class="help text-danger" v-show="errors.has('remarks')">
                    {{ errors.first("remarks") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Retry Counter</label>
                  <input type="number" id="retry_counter" name="retry_counter" v-bind:class="{'has-error' : errors.has('retry_counter')}" v-validate="'required'" v-model="value.retry_counter" class="form-control retry_counter" placeholder="Retry Counter">
                  <div class="help text-danger" v-show="errors.has('retry_counter')">
                    {{ errors.first("retry_counter") }}
                  </div>
                </div>
                <div class="form-group col-6 required">
                  <label class="control-label">Processing At</label>
                    <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'processing_at'}" v-model="value.processing_at" lang="en" format="YYYY-MM-DD HH:mm:ss" id="processing_at" value-type="YYYY-MM-DD HH:mm:ss"></date-picker>
                  <div class="help text-danger" v-show="errors.has('processing_at')">
                    {{ errors.first("processing_at") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import { CBSPaymentApi, CBSCommonApi } from '@/services/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'PaymentTransactionEventForm',
  metaInfo: {
    title: 'Payment Transaction Events | Dotlines'
  },
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {},
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true
          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSPaymentApi.updatePaymentTransactionEvent(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'PaymentTransactionEventList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$toaster.error(error.data);
              })
          } else {
            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSPaymentApi.storePaymentTransactionEvent(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'PaymentTransactionEventList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.data)
              })
          }
        }
      });
    },
    getDetail: function (id) {
      CBSPaymentApi.showPaymentTransactionEvent(id)
        .then((item) => {
          this.value = item
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        this.getDetail(this.params.id)
      }
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>

